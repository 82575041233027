
import { apolloClient } from "@/api/apolloClient";
import { CREATE_TAG_CATEGORY, UPDATE_TAG_CATEGORY, DELETE_TAG_CATEGORY, CREATE_TAG, UPDATE_TAG, DELETE_TAG } from "../api/graphql/mutation/TagMutation";
import { TAG_CATEGORIES, TAG_CATEGORIES_ADMIN } from "../api/graphql/query/TagQuery";

export default {
    createTagCategory: function(form) {
        const { originalLanguage, localizations } = form
        return apolloClient.mutate({
            mutation: CREATE_TAG_CATEGORY,
            variables: {
                originalLanguage,
                localizations,
            },
            update: async (store, {data: {createTagCategory}}) => {
                let data = await store.readQuery({ query: TAG_CATEGORIES_ADMIN });
                data = {
                    ...data,
                    tagCategoriesAdmin: [
                        createTagCategory,
                      ...data.tagCategoriesAdmin,
                    ],
                  }
                store.writeQuery({query: TAG_CATEGORIES_ADMIN, data})
            }
        }).then((result) => {
            console.log("Category created: ", result);
            return result;
        });
    },
    
    updateTagCategory: function(form) {
        const { id, originalLanguage, localizations } = form
        return apolloClient.mutate({
            mutation: UPDATE_TAG_CATEGORY,
            variables: {
                id,
                originalLanguage,
                localizations,
            },
            update: async (store, {data: {updateTagCategory}}) => {
                const { tagCategoriesAdmin } = await store.readQuery({ query: TAG_CATEGORIES_ADMIN });
                const updatedData = {
                    tagCategoriesAdmin: tagCategoriesAdmin.map(category => {
                        if (category.id == form.id) {
                            return {
                                ...category,
                                localizations
                            }
                        }
                        return category
                    })
                  }
                store.writeQuery({query: TAG_CATEGORIES_ADMIN, data: updatedData})
            }
        }).then((result) => {
            console.log("Category updated: ", result);
            return result;
        });
    },
    
    deleteTagCategory: function(id) {
        return apolloClient.mutate({
            mutation: DELETE_TAG_CATEGORY,
            variables: {
                id: id,
            },
            update: async (store, {data: {deleteTagCategory}}) => {
                try {
                    const { tagCategoriesAdmin } = await store.readQuery({ query: TAG_CATEGORIES_ADMIN });
                    const categoryIndex = tagCategoriesAdmin.findIndex(category => category.id === id);
                
                    if (categoryIndex !== -1) {
                      const updatedTagCategories = [...tagCategoriesAdmin];
                      updatedTagCategories.splice(categoryIndex, 1);
                
                      const updatedData = {
                        tagCategoriesAdmin: updatedTagCategories
                      };
                
                      store.writeQuery({ query: TAG_CATEGORIES_ADMIN, data: updatedData });
                    }
                  } catch (error) {
                    console.error('Error updating cache:', error);
                  }
            }
        }).then((result) => {
            console.log("Category deleted: ", result);
            return result;
        });
    },
    
    createTag: function(form, tagCategoryId) {
        const { originalLanguage, localizations } = form
        return apolloClient.mutate({
            mutation: CREATE_TAG,
            variables: {
                tagCategoryId: tagCategoryId,
                originalLanguage,
                localizations
            },
            update: async (store, {data: {createTag}}) => {
                const { tagCategoriesAdmin } = await store.readQuery({ query: TAG_CATEGORIES_ADMIN });
                const updatedTagCategories = tagCategoriesAdmin.map(category => {
                    if (category.id == tagCategoryId) {
                        return {
                            ...category,
                            tags: category.tags ? [...category.tags, createTag] : [createTag]
                        }
                    }
                    return category
                })
                const updatedData = {
                    tagCategoriesAdmin: updatedTagCategories
                };
                store.writeQuery({query: TAG_CATEGORIES_ADMIN, data: updatedData})
            }
        }).then((result) => {
            console.log("Tag created: ", result);
            return result;
        });
    },
    
    updateTag: function(form, tagCategoryId) {
        const { originalLanguage, localizations } = form
        return apolloClient.mutate({
            mutation: UPDATE_TAG,
            variables: {
                id: form.id,
                originalLanguage,
                localizations,
                tagCategoryId: tagCategoryId,
            },
            update: async (store, {data: {updateTag}}) => {
                const data = await store.readQuery({ query: TAG_CATEGORIES_ADMIN });
                console.log("data!!!", data)
                const tagCategoriesAdmin = data.tagCategoriesAdmin
                const updatedData = {
                    tagCategoriesAdmin: tagCategoriesAdmin.map(category => {
                        if (category.id == tagCategoryId) {
                            return {
                                ...category,
                                tags: category.tags.map(tag => {
                                    if (tag.id == form.id) {
                                        return {
                                            ...tag,
                                            localizations
                                        }
                                    }
                                    return tag
                                }),
                            }
                        }
                        return category
                    })
                  }
                store.writeQuery({query: TAG_CATEGORIES_ADMIN, data: updatedData})
            }
        }).then((result) => {
            console.log("Tag updated: ", result);
            return result;
        });
    },
    
    deleteTag: function(id, tagCategoryId) {
        return apolloClient.mutate({
            mutation: DELETE_TAG,
            variables: {
                id: id,
            },
            update: async (store, {data: {deleteTag}}) => {
                try {
                    const { tagCategoriesAdmin } = await store.readQuery({ query: TAG_CATEGORIES_ADMIN });
                    const categoryIndex = tagCategoriesAdmin.findIndex(category => category.id === tagCategoryId);
                  
                    if (categoryIndex !== -1) {
                        const updatedTagCategories = [...tagCategoriesAdmin];
                        updatedTagCategories[categoryIndex] = {...updatedTagCategories[categoryIndex], tags: updatedTagCategories[categoryIndex].tags.filter(tag => tag.id !== id)}
                  
                        const updatedData = {
                            tagCategoriesAdmin: updatedTagCategories,
                        }
                    
                        store.writeQuery({ query: TAG_CATEGORIES_ADMIN, data: updatedData });
                    }
                  } catch (error) {
                    console.error('Error updating cache:', error);
                  }
            }
        }).then((result) => {
            console.log("Tag deleted: ", result);
            return result;
        });
    }
}