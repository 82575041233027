<template>
    <div class="text-left">
        <b-alert :show="alert.dismissCountDown" fade id="alert"
                @dismiss-count-down="countDownChanged"
                class="alert-success">{{alert.notification}}
        </b-alert>
        <b-alert :show="alertDanger.dismissCountDown" fade id="alert-danger"
                @dismiss-count-down="countDownChangedDanger"
                class="alert-danger">{{alertDanger.notification}}
                <div class="x dismiss-x" @click="() => {alertDanger.dismissCountDown = 0}"></div>
        </b-alert>
        <div class="d-flex align-items-center justify-content-between mb-4">
            <h1>{{ $t("admin.tagCategories") }}</h1>
            <TrekioSwitch v-model="isManagingLocalizations" :constantText="$t('admin.translation')"/>
        </div>

        <div class="d-flex justify-content-between align-items-center row mx-0 w-100 mb-4">
            <div class="col-6 px-0">
                <TrekioSelect :label="$t('inputLabels.choosePrimaryLanguage')" :placeholder="$t('inputLabels.choosePrimaryLanguage')"
                    :text="primaryLanguage" v-model="primaryLanguage"
                >   
                    <TrekioSelectOption v-for="language in languages" :key="language.code" :value="language.code">
                        {{ language.code }}
                    </TrekioSelectOption>
                </TrekioSelect>
            </div>
            <div class="col-6">
                <TrekioSelect v-if="isManagingLocalizations" :label="$t('inputLabels.showTranslation')" :placeholder="$t('inputLabels.showTranslation')"
                    :text="editedLanguage ? editedLanguage : $t('admin.notSelected')" v-model="editedLanguage"
                >   
                    <TrekioSelectOption :value="null">{{ $t("admin.notSelected") }}</TrekioSelectOption>
                    <TrekioSelectOption v-for="language in translatedLanguages" :key="language" :value="language">
                        {{ language }}
                    </TrekioSelectOption>
                </TrekioSelect>
            </div>
        </div>
        <TrekioInput class="mb-4" id="search" searchInput v-model="searchString" :placeholder="$t('general.search')"></TrekioInput>
        <div v-if="$apollo.loading" class="mt-4 loader-1 center paging-loading"><span></span></div>
        <div v-else>
            <div v-if="!isManagingLocalizations">
                <a class="add-new-category-collapse text-center" v-b-toggle.addCategory> + {{ $t("admin.addCategory") }}</a>
                <b-collapse id="addCategory">
                    <form action="submit" @submit.prevent="createTagCategory" novalidate>
                        <TrekioInput id="title" trim v-model="newCategoryTitle" :label="$t('inputLabels.categoryTitle')" maxlength="50" required
                            :error="error.title"
                        />
                        <TrekioButton primary :isLoading="loading != ''" :loadingText="loading">{{ $t("buttons.createCategory") }}</TrekioButton>
                    </form>
        
                </b-collapse>
            </div>
            <p class="pt-4" v-if="searchString && tagCategoriesAdmin && tagCategoriesAdmin.length && !tagCategoriesAdminFiltered.length">{{ $t("filter.noResults") }}</p>
            <TagCategory v-for="category in tagCategoriesAdminFiltered" :allTagTitles="allTagTitlesSet" :key="category.id" :category="category" @showAlert="showAlert" @showAlertDanger="showAlertDanger" :isManagingLocalizations="isManagingLocalizations"
                :primaryLanguage="primaryLanguage" :editedLanguage="editedLanguage"
            />
        </div>
    </div>
</template>


<script>
    import TagService from '../../services/TagService';
    import TagCategory from '../../components/admin/TagCategory.vue';
    import { TAG_CATEGORIES_ADMIN } from '../../api/graphql/query/TagQuery';
    import TrekioSwitch from '../../components/TrekioSwitch.vue';
    import sharedUtils from '../../utils/sharedUtils';

    export default {
        data() {
            return {
                searchString: "",
                isManagingLocalizations: false,
                primaryLanguage: "cs",
                editedLanguage: "en",
                newCategoryTitle: "",
                form: {
                    originalLanguage: "cs",
                    localizations: this.$store.state.languages.map(lang => {
                        return {
                            language: lang.code,
                            title: "",
                            description: ""
                        }
                    })
                },
                error: {
                    title: '',
                },
                alert: {
                    dismissSecs: 4,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                alertDanger: {
                    dismissSecs: 999,
                    dismissCountDown: 0,
                    showDismissibleAlert: false,
                    notification: ''
                },
                loading: '',
            }
        },

        components: {
            TagCategory,
            TrekioSwitch,
        },

        created() {
            this.$root.$on('showAlertTag', this.showAlert)
            this.$root.$on('showAlertDangerTag', this.showAlertDanger)
        },

        computed: {
            tagCategoriesAdminFiltered() {
                if (!this.searchString) {
                    return this.tagCategoriesAdmin
                }
                return this.tagCategoriesAdmin.map(category => {
                    return {
                        ...category,
                        tags: category.tags?.filter(tag => {
                            return tag.localizations.some(localization => localization.title.toLowerCase().includes(this.searchString.toLowerCase()))
                        })
                    }
                }).filter(category => category.tags?.length)
            },
            languages() {
                return this.$store.state.languages
            },
            translatedLanguages() {
                return this.form.localizations.map(localization => localization.language).filter(lang => {
                    return lang != this.primaryLanguage
                }).sort((a, b) => a.localeCompare(b))
            },
            allTagTitlesSet() {
                const tagTitlesSet = new Set()
                this.tagCategoriesAdmin.forEach(category => {
                    category.tags?.forEach(tag => {
                        tag.localizations.forEach(localization => {
                            tagTitlesSet.add(localization.title.toLowerCase())
                        });
                    })
                });
                return tagTitlesSet
            }
        },

        watch: {
            primaryLanguage(nV) {
                if (nV == this.editedLanguage) this.editedLanguage = null
                this.sortLocalizations()
            },
            editedLanguage(nV) {
                this.sortLocalizations()
            }
        },

        methods: {
            sortLocalizations() {
                sharedUtils.sortLocalizations(this.form.localizations, this.primaryLanguage, this.editedLanguage)
            },
            showAlert(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alert.dismissCountDown = this.alert.dismissSecs
                this.alert.notification = notification;
            },

            showAlertDanger(notification) {
                this.alert.dismissCountDown = this.alertDanger.dismissCountDown = 0
                this.loading = ''
                this.alertDanger.dismissCountDown = this.alertDanger.dismissSecs
                this.alertDanger.notification = notification
            },
            countDownChanged(dismissCountDown) {
                this.alert.dismissCountDown = dismissCountDown
            },

            countDownChangedDanger(dismissCountDown) {
              this.alertDanger.dismissCountDown = dismissCountDown
            },
            async createTagCategory() {
                this.error.title = ''
                if (!this.newCategoryTitle) {
                    return this.error.title = this.$t("inputErrors.required")
                }
                this.form.localizations = this.form.localizations.map(localization => {
                    return {
                        language: localization.language,
                        title: localization.language == this.primaryLanguage ? this.newCategoryTitle : ""
                    }
                })
                this.form.originalLanguage = this.primaryLanguage
                this.loading = this.$t("loading.tagCategoryCreate")
                await TagService.createTagCategory(this.form)
                    .then(resp => {
                        console.log(resp)
                        this.$root.$emit('bv::toggle::collapse', 'addCategory')
                        this.$nextTick(() => {
                            this.$root.$emit('bv::toggle::collapse', 'tag-category' + resp.data.createTagCategory.id)
                        })
                        this.newCategoryTitle = ""
                        this.showAlert(this.$t("alerts.tagCategoryCreated"))
                    })
                    .catch(err => {
                        console.log(err)
                        this.showAlertDanger(this.$t("alerts.tagCategoryCreateError"))
                    })
            },
        },


        apollo: {
            tagCategoriesAdmin: {
                query: TAG_CATEGORIES_ADMIN,
            }
        }
    }


</script>

<style lang="scss" scoped>
    @import '@/scss/variables';

    .loading {
        font-size: 25px;
    }

    h1 {
        font-size: 50px !important;
        font-weight: 700 !important;
    }

    form {
        margin-block: 20px 40px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .add-new-category-collapse {
        font-size: 25px;
        line-height: 25px;
        color: $tr-black;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: dotted 3px $tr-light-gray;
        border-top: dotted 3px $tr-light-gray;
        height: 80px;
        cursor: pointer;

        &:hover{
            color: $tr-black;
            text-decoration: none;
        }

        &::after{
            content: "";
            position: absolute;
            right: 0;
            display: inline-block;
            width: 40px;
            height: 14px;
            background-position: left top;
            background-repeat: no-repeat;
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M22 2L12 12L2 2' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            background-size: 24px 15px;
        }

        &[aria-expanded="true"]{
            &::after{
            background-image: url("data:image/svg+xml,%3Csvg width='24' height='15' viewBox='0 0 24 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2 13L12 3L22 13' stroke='%23222222' stroke-width='3'/%3E%3C/svg%3E%0A");
            }
        }
    }

</style>